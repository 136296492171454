<template>
	<main class="c_content_container main">
		<div class="mobile_banner">
			<h1>
				<p class="banner banner_text_first">금융의 창을 통해</p>
				<p class="banner banner_text_second">내일을 바라봅니다.</p>
			</h1>
		</div>
		<div class="main_banner">
			<video v-if="this.windowSize > 1100" autoplay="autoplay" muted="muted" loop="loop" playsinline>
				<source src="@/assets/image/movie/movie_big.mp4" type="video/mp4" />
			</video>
			<video v-else autoplay="autoplay" muted="muted" loop="loop" playsinline>
				<source src="@/assets/image/movie/movie_small.mp4" type="video/mp4" />
				<img src="@/assets/image/movie/main_mobile.gif" alt="main_video" />
			</video>
			<!-- <h1>금융의 창을 통해 <br />내일을 바라봅니다.</h1> -->
		</div>

		<section class="c_section value">
			<div class="c_inner">
				<div v-slide="{ delay: 150 }" class="section_title">
					<h1>금융전문가교육!</h1>
					<h1>오직 <strong>휴먼밸류</strong>에서만 가능합니다.</h1>
					<div class="under_bar"></div>
					<p>
						차별화된 금융전문가 양성을 위한 최선의 선택. <br />
						휴먼밸류는 고객사의 금융전문성 및 역량 강화만을 고민합니다.
					</p>
				</div>
				<div class="value_content_wrap">
					<div v-slide.vertical="{ delay: 150 }" class="value_content right">
						<span class="text_opacity">Professional Education</span>
						<div class="text_wrap">
							<p>금융전문가교육 since 2002</p>
							<h2><span class="font_english">23</span>년 경력</h2>
						</div>
						<div v-circleMotion class="image_wrap">
							<img src="@/assets/image/main/img_value_01.png" alt="" />
						</div>
					</div>
					<div v-slide.vertical="{ delay: 150 }" class="value_content left">
						<span class="text_opacity">Client Company</span>
						<div class="text_wrap">
							<p>고객사(은행, 증권, 보험, 일반)</p>
							<h2><span class="font_english">70</span>개 고객사</h2>
						</div>
						<div v-circleMotion class="image_wrap">
							<img src="@/assets/image/main/img_value_02.png" alt="" />
						</div>
					</div>
					<div v-slide.vertical="{ delay: 150 }" class="value_content right">
						<span class="text_opacity">Educational Program</span>
						<div class="text_wrap">
							<p>단기/장기 금융교육프로그램</p>
							<h2><span class="font_english">1420</span>개 교육프로그램</h2>
						</div>
						<div v-circleMotion class="image_wrap">
							<img src="@/assets/image/main/img_value_03.png" alt="" />
						</div>
					</div>
				</div>
			</div>
		</section>

		<section class="c_section ceo_message">
			<div v-slide="{ delay: 150 }" class="section_title">
				<h1 class="font_english">CEO <br />Message</h1>
				<div class="under_bar"></div>
			</div>
			<div class="message_wrap">
				<div v-slide.vertical="{ delay: 150 }" class="text_wrap">
					<h1>
						언제나 최선을 다하는 기업<br />
						<strong>휴먼밸류</strong>
					</h1>
					<ul>
						<li>
							금융은 얼핏 차가운 숫자와 냉철한 투자만 존재하는 것처럼 보입니다. 하지만 기업의 역사와 국가의 경계
							사이에서 미래와 혁신이 담겨있는 것, 그것이 ‘금융’입니다.
						</li>
						<li>
							㈜휴먼밸류는 금융이라는 훌륭한 잣대를 통해 세상을 제대로 보고 통찰과 지혜를 축적시키는 과정을 통해 콘텐츠
							생산 / 교육 및 컨설팅 / 데이터 분석을 하고 있습니다.
						</li>
						<li>
							산업 간의 경계, 학문 간의 경계, 지식 간의 경계가 허물어져 가는 혁신의 시대에 ㈜휴먼밸류는 새로운 금융
							환경에 적합한 콘텐츠와 지식으로 전문가의 성장을 돕고 있습니다.
						</li>
						<li>
							새 시대를 여는 인사이트를 얻고 조직의 新성장 동력을 찾고자 하는 전문가와 기업을 돕기 위해 불철주야
							노력하는 그 곳, 바로 ㈜휴먼밸류입니다.
						</li>
						<li>함께 미래를 만들고 해답을 찾겠습니다.</li>
						<li>고맙습니다.</li>
					</ul>
					<div class="sign">
						<p>㈜휴먼밸류 대표이사 박경화</p>
						<img src="@/assets/image/main/sign.png" alt="" />
					</div>
				</div>
				<div class="image_wrap">
					<img src="@/assets/image/common/img_CEO_v2.png" alt="" />
				</div>
			</div>
		</section>

		<section class="c_section special_project">
			<div v-slide="{ delay: 150 }" class="section_title">
				<h1 class="font_english">Special <br />Project</h1>
				<div class="under_bar"></div>
			</div>
			<swiper
				v-slide:keep.child.vertical="{ delay: 150 }"
				v-bind="SwiperOption"
				:scrollbar="{
					hide: false,
				}"
				class="mySwiper"
			>
				<swiper-slide>
					<h1>2024</h1>
					<div class="list_wrap">
						<ul>
							<li>
								<p><span></span>승진자 연수 (상∙하반기)</p>
								<span>BNK금융그룹</span>
							</li>
							<li>
								<p><span></span>인공지능 트렌드 마스터 플랜 (연간)</p>
								<span>IBK투자증권</span>
							</li>
							<li>
								<p><span></span>영업전환직원 교육 (상∙하반기)</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>Pre-PB 세일즈 코칭 (연간)</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>기업보험 직무연수 (연간)</p>
								<span>현대해상</span>
							</li>
							<li>
								<p><span></span>WM&투자직무 전문∙심화과정(연간)</p>
								<span>삼성금융그룹</span>
							</li>
							<li>
								<p><span></span>PIB 실무교육</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>PB직무교육 (상반기)</p>
								<span>삼성증권</span>
							</li>
							<li>
								<p><span></span>PB양성∙테마교육 (연간)</p>
								<span>교보증권</span>
							</li>
							<li>
								<p><span></span>주식투자 전문가과정</p>
								<span>대신증권</span>
							</li>
							<li class="last">
								<p><span></span>상기 프로젝트 외 30개 프로젝트 수행</p>
							</li>
						</ul>
					</div>
				</swiper-slide>
				<swiper-slide>
					<h1>2023</h1>
					<div class="list_wrap">
						<ul>
							<li>
								<p><span></span>채권투자전문가과정</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>CS예비리더과정</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>금융지식스쿨(연간)</p>
								<span>IBK투자증권</span>
							</li>
							<li>
								<p><span></span>WM&투자직무 전문∙심화과정(연간) 1기~2기</p>
								<span>삼성금융그룹</span>
							</li>
							<li>
								<p><span></span>세무∙부동산 컨설팅 전문가과정</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>챔피언PB보수과정(연간)</p>
								<span>유진투자증권</span>
							</li>
							<li>
								<p><span></span>금융상품 전문가과정(연간)</p>
								<span>교보증권</span>
							</li>
							<li>
								<p><span></span>IB실무과정</p>
								<span>NH투자증권</span>
							</li>
							<li>
								<p><span></span>미국주식 전문가과정</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>Pre-PB 세일즈과정</p>
								<span>KB증권</span>
							</li>
							<li class="last">
								<p><span></span>상기 프로젝트 외 33개 프로젝트 수행</p>
							</li>
						</ul>
					</div>
				</swiper-slide>
				<swiper-slide>
					<h1>2022</h1>
					<div class="list_wrap">
						<ul>
							<li>
								<p><span></span>자산관리 기본과정 & 심화과정</p>
								<span>BNK경남은행</span>
							</li>
							<li>
								<p><span></span>글로벌 투자마스터 4기</p>
								<span>미래에셋증권</span>
							</li>
							<li>
								<p><span></span>HNW고객 세일즈를 위한 이미지 코칭과정</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>부동산 컨설팅 전문가과정 1기~2기</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>챔피언PB 양성과정 3기</p>
								<span>유진투자증권</span>
							</li>
							<li>
								<p><span></span>세무 컨설팅 전문가과정 1기~2기</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>기업보험 Innovation, 기업보험 Skill Up 외</p>
								<span>현대해상</span>
							</li>
							<li>
								<p><span></span>세일즈스킬 전문가과정 1기~3기</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>부동산&세금 컨설팅 School</p>
								<span>삼성증권</span>
							</li>
							<li>
								<p><span></span>글로벌 투자 레벨업</p>
								<span>미래에셋증권</span>
							</li>
							<li class="last">
								<p><span></span>상기 프로젝트 외 35개 프로젝트 수행</p>
							</li>
						</ul>
					</div>
				</swiper-slide>
				<swiper-slide>
					<h1>2021</h1>
					<div class="list_wrap">
						<ul>
							<li>
								<p><span></span>글로벌 투자마스터 1기~3기</p>
								<span>미래에셋증권</span>
							</li>
							<li>
								<p><span></span>글로벌투자 인사이트</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>해외주식 투자전략(전 영업점)</p>
								<span>한화투자증권</span>
							</li>
							<li>
								<p><span></span>PB팀장 리더십 강화과정</p>
								<span>한국투자증권</span>
							</li>
							<li>
								<p><span></span>챔피언PB 양성과정 2기</p>
								<span>유진투자증권</span>
							</li>
							<li>
								<p><span></span>산업별 동향파악 과정</p>
								<span>현대해상</span>
							</li>
							<li>
								<p><span></span>리스크 매니지먼트 과정</p>
								<span>현대차증권</span>
							</li>
							<li>
								<p><span></span>전 영업점 CS방문교육</p>
								<span>한국투자증권</span>
							</li>
							<li>
								<p><span></span>글로벌 금융 아카데미</p>
								<span>IBK투자증권</span>
							</li>
							<li>
								<p><span></span>자산관리 기본과정 & 심화과정</p>
								<span>BNK경남은행</span>
							</li>
							<li class="last">
								<p><span></span>상기 프로젝트 외 27개 프로젝트 수행</p>
							</li>
						</ul>
					</div>
				</swiper-slide>
				<swiper-slide>
					<h1>2020</h1>
					<div class="list_wrap">
						<ul>
							<li>
								<p><span></span>Expert Academy (연간)</p>
								<span>삼성증권</span>
							</li>
							<li>
								<p><span></span>변액보험 리스타트</p>
								<span>미래에셋생명</span>
							</li>
							<li>
								<p><span></span>전 영업점 CS방문교육</p>
								<span>한국투자증권</span>
							</li>
							<li>
								<p><span></span>해외주식 투자전략</p>
								<span>현대차증권</span>
							</li>
							<li>
								<p><span></span>성과향상 프로그램</p>
								<span>미래에셋증권</span>
							</li>
							<li>
								<p><span></span>新성장 산업분석 심화과정</p>
								<span>DB금융투자</span>
							</li>
							<li>
								<p><span></span>특성화고 신입사원 교육</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>언택트 해외주식 심화과정</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>FM직군 전환교육</p>
								<span>삼성증권</span>
							</li>
							<li>
								<p><span></span>신입행원 연수</p>
								<span>BNK부산은행</span>
							</li>
							<li class="last">
								<p><span></span>상기 프로젝트 외 16개 프로젝트 수행</p>
							</li>
						</ul>
					</div>
				</swiper-slide>
				<swiper-slide>
					<h1>2019</h1>
					<div class="list_wrap">
						<ul>
							<li>
								<p><span></span>PB 역량강화교육</p>
								<span>광주은행</span>
							</li>
							<li>
								<p><span></span>금융주치의MBA</p>
								<span>대신증권</span>
							</li>
							<li>
								<p><span></span>변액 New Start 과정</p>
								<span>미래에셋생명</span>
							</li>
							<li>
								<p><span></span>PB Level-Up</p>
								<span>BNK경남은행</span>
							</li>
							<li>
								<p><span></span>WM Academy</p>
								<span>미래에셋대우</span>
							</li>
							<li>
								<p><span></span>중간관리사 관계리더십 향상과정</p>
								<span>BNK부산은행</span>
							</li>
							<li>
								<p><span></span>세무•부동산 전문가과정</p>
								<span>미래에셋대우</span>
							</li>
							<li>
								<p><span></span>주말열린테마강좌 (연간)</p>
								<span>BNK금융지주</span>
							</li>
							<li>
								<p><span></span>Star Analyst Invitation</p>
								<span>KB증권</span>
							</li>
							<li>
								<p><span></span>비대면 영업조직 역량향상교육</p>
								<span>삼성증권</span>
							</li>
							<li class="last">
								<p><span></span>상기 프로젝트 외 30개 프로젝트 수행</p>
							</li>
						</ul>
					</div>
				</swiper-slide>
			</swiper>
		</section>

		<section class="c_section client_company">
			<div class="c_inner">
				<div v-slide="{ delay: 150 }" class="section_title">
					<h1 class="font_english">Client <br />Company</h1>
					<div class="under_bar"></div>
				</div>
				<div class="company_wrap">
					<div v-slide.vertical="{ delay: 150 }" class="company">
						<h2>은행</h2>
						<div class="logo_wrap">
							<div>
								<img src="@/assets/image/main/ci_bank/bank_02.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_01.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_05.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_07.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_03.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_06.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_08.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_09.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_12.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_04.png" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_10.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_bank/bank_11.svg" alt="" />
							</div>
						</div>
					</div>
					<div v-slide.vertical="{ delay: 150 }" class="company">
						<h2>증권</h2>
						<div class="logo_wrap">
							<div class="bigger">
								<img src="@/assets/image/main/ci_stock/stock_01.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_03.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_04.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_stock/stock_05.svg" alt="" />
							</div>
							<div class="">
								<img src="@/assets/image/main/ci_stock/stock_18.png" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_stock/stock_06.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_stock/stock_07.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_stock/stock_08.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_17.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_stock/stock_09.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_stock/stock_02.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_11.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_12.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_stock/stock_13.png" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_15.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_16.png" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_stock/stock_14.svg" alt="" />
							</div>
						</div>
					</div>
					<div v-slide.vertical="{ delay: 150 }" class="company">
						<h2>보험</h2>
						<div class="logo_wrap">
							<div class="bigger">
								<img src="@/assets/image/main/ci_insure/insure_03.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_insure/insure_01.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_insure/insure_04.png" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_insure/insure_02.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_insure/insure_07.png" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_insure/insure_05.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_insure/insure_06.svg" alt="" />
							</div>
						</div>
					</div>
					<div v-slide.vertical="{ delay: 150 }" class="company">
						<h2>일반</h2>
						<div class="logo_wrap">
							<div class="bigger">
								<img src="@/assets/image/main/ci_common/common_01.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_02.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_common/common_03.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_04.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_05.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_06.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_07.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_08.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_09.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_common/common_10.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_11.svg" alt="" />
							</div>
							<div class="">
								<img src="@/assets/image/main/ci_common/common_12.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_13.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_14.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_common/common_15.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_common/common_16.png" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_17.svg" alt="" />
							</div>
							<div class="bigger">
								<img src="@/assets/image/main/ci_common/common_18.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_19.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_20.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_21.svg" alt="" />
							</div>
							<div class="small">
								<img src="@/assets/image/main/ci_common/common_22.svg" alt="" />
							</div>
							<div class="big">
								<img src="@/assets/image/main/ci_common/common_23.svg" alt="" />
							</div>
							<div>
								<img src="@/assets/image/main/ci_common/common_24.svg" alt="" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
	<Contact_Banner />
</template>

<script>
import Contact_Banner from '@/components/Contact_banner';
import anime from 'animejs/lib/anime.es.js';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/bundle';

// import required modules
import { Scrollbar } from 'swiper';

export default {
	components: {
		Contact_Banner,
		Swiper,
		SwiperSlide,
	},
	data() {
		return {
			windowSize: window.innerWidth,
			SwiperOption: {
				// 스와이퍼 옵션
				slidesPerView: 'auto',
				modules: [Scrollbar],
			},
			timeoutId: null,
		};
	},
	mounted() {
		this.playMobileAnimation();
		if (window.innerWidth < 479) {
			document.querySelector('#app').classList.add('scroll_fixed');
			this.timeoutId = setTimeout(() => {
				document.querySelector('#app').classList.remove('scroll_fixed');
				clearTimeout(this.timeoutId);
			}, 3000);
		}
		window.addEventListener('resize', this.getWindowSize);
		window.addEventListener('scroll', this.watchScroll);
	},
	unmounted() {
		window.removeEventListener('resize', this.getWindowSize);
		window.removeEventListener('scroll', this.watchScroll);
	},
	methods: {
		getWindowSize() {
			this.windowSize = window.innerWidth;
		},
		playMobileAnimation() {
			const bannerFirstEl = document.querySelector('.banner_text_first');
			bannerFirstEl.innerHTML = bannerFirstEl.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
			const bannerSecondEl = document.querySelector('.banner_text_second');
			bannerSecondEl.innerHTML = bannerSecondEl.textContent.replace(/\S/g, "<span class='letter'>$&</span>");
			anime
				.timeline({ loop: false })
				.add({
					targets: '.banner_text_first .letter',
					translateY: [-200, 0],
					easing: 'easeOutExpo',
					duration: 2000,
					delay: (el, i) => 100 * i,
				})
				.add(
					{
						targets: '.banner_text_second .letter',
						translateY: [-200, 0],
						easing: 'easeOutExpo',
						duration: 2000,
						delay: (el, i) => 100 * i,
					},
					'-=1200'
				);
		},
	},
};
</script>

<style scoped src="@/assets/css/home.css"></style>
