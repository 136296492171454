<template>
  <ToTop />
  <Header />
  <router-view />
  <Footer />
</template>

<script>
import ToTop from '@/components/ToTop'
import Header from "@/components/Header";
import Footer from "@/components/Footer";
import { postData } from "./utils/postData";

export default {
  components: {
    ToTop,
    Header,
    Footer,
  },
  mixins: [postData],
  mounted() {
    this.axios.get('https://ipapi.co/json/')  // clientIP 알아냄
    .then(res => {
      const clientIP = res.data.ip;
      this.axios.post('/saveVisitor', { // 방문자 저장
        ip: clientIP
      })
    }).catch(error => {
      console.error(error, '방문자 저장에 실패했습니다');
    })
  },
};
</script>

<style>
@import url("./assets/css/reset.css");
@import url("./assets/css/common.css");
@import url("./assets/css/layout.css");
</style>
